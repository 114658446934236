import React, { useState, useRef, useEffect } from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';

import LinkTransition from '../../components/linkTransition';
import { ThemeContext } from '../../context/theme-context';

import Layout from '../../components/layout';
import Footer from '../../components/footer';
import ContactBlock from '../../components/contactBlock';
import IconArrow from '../../assets/icoArrowRightYellow.inline.svg';
import classNames from 'classnames';
import insights from '../../../static/insgnitus.png';

const EventTest = ({ children, img }) => {
    const elementRef = useRef(null);

    const hoverImg = (e) => {
        return;
        elementRef.current.style.display = 'block';
        elementRef.current.style.left = `${e.clientX}px`;
        elementRef.current.style.top = `${e.clientY}px`;
        elementRef.current.style.backgroundImage = `radial-gradient(ellipse at center, transparent 0%, #1B1D1D 60% 100%), url(${insights})`;
    };

    const removeHoverImg = () => {
        elementRef.current.style.display = 'none';
    };

    const locateInsights = () => {
        const insights = document.getElementsByClassName('insights');
        const insightsArray = Array.from(insights);
        insightsArray.map((el, id) => {
            insights[id].getBoundingClientRect().top <
                window.innerHeight - 100 &&
                el.classList.add('secondary-insights');
        });
    };

    React.useEffect(() => {

      window.addEventListener('scroll', locateInsights);

    });

    return (
        <div
            className="circle"
            onMouseMove={(e) => hoverImg(e)}
            onMouseOut={(e) => removeHoverImg(e)}
        >
            <div ref={elementRef} className="background-matte-image"></div>
            {children}
        </div>
    );
};

const InsightsPage = ({ location, data }) => {
    const [visible, setVisible] = useState(3);
    const showButton = data.allAtuoInsights.nodes.length > visible;

    let count = 1;

    return (
        <Layout bg="black" location={location}>
            <Helmet>
                <title>ATUO - Insights</title>
            </Helmet>

            <div className="container">
                <div className="size-hero grid content-center py-60 md:py-80">
                    <h1 className="text-7xl md:text-9xl">
                        insights <br />
                        <span className="text-yellow">&</span> cultura
                    </h1>
                </div>

                {data.allAtuoInsights.nodes.map((node, idx) => {
                    count++;

                    return (
                        <div key={idx}>
                            {idx < visible && (
                                <article
                                    key={node.slug}
                                    className={classNames({
                                        'border-b': count < 6,
                                        'border-gray-500': count < 6,
                                        'py-20': true,
                                    })}
                                >
                                    {idx < 2 ? (
                                        <>
                                            <div className="w-full md:w-3/6">
                                                <EventTest img="https://resultadosdigitais.com.br/files/2015/08/thestocks-imagem.jpg">
                                                    <h3 className="text-4xl md:text-7xl mb-10 md:leading-tight">
                                                        {node.title}
                                                    </h3>
                                                </EventTest>
                                            </div>
                                            <div className="flex flex-col md:flex-row justify-between">
                                                <EventTest img="https://st.depositphotos.com/1017908/1548/i/450/depositphotos_15489579-stock-photo-amazing-mountain-with-red-leaf.jpg">
                                                    <div className="w-full mb-10 md:mb-0">
                                                        <p className="text-2xl md:text-4xl md:leading-snug">
                                                            {node.description}
                                                        </p>
                                                    </div>
                                                </EventTest>
                                                <div className="w-full md:w-2/6 text-lg md:leading-relaxed">
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: node.excerpt,
                                                        }}
                                                    />
                                                    <div className="mt-10 md:mt-0 flex justify-end">
                                                        <LinkTransition
                                                            to={node.slug}
                                                            className="link-transition content-center justify-end flex w-max"
                                                        >
                                                            <EventTest>
                                                                <span className="text-yellow mr-2">
                                                                    continuar
                                                                    lendo
                                                                </span>
                                                            </EventTest>
                                                            <IconArrow className="mt-1" />
                                                        </LinkTransition>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div
                                                id="insights"
                                                className="insights flex flex-col md:flex-row justify-between md:gap-32"
                                            >
                                                <div className="w-full md:w-1/2">
                                                    <EventTest>
                                                        <h3 className="text-4xl md:text-5xl mb-10 md:leading-tight">
                                                            {node.title}
                                                        </h3>
                                                    </EventTest>
                                                </div>
                                                <div className="w-full md:w-1/2 flex justify-center flex-col text-lg md:leading-relaxed">
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: node.excerpt,
                                                        }}
                                                    />
                                                    <EventTest>
                                                        <p className="my-10 md:text-right">
                                                            {node.description}
                                                        </p>
                                                    </EventTest>
                                                    <div className="flex justify-end">
                                                        <LinkTransition
                                                            to={node.slug}
                                                            className="link-transition content-center justify-end flex w-max"
                                                        >
                                                            <EventTest>
                                                                <span className="text-yellow mr-2">
                                                                    continuar
                                                                    lendo
                                                                </span>
                                                            </EventTest>
                                                            <IconArrow className="mt-1" />
                                                        </LinkTransition>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </article>
                            )}
                        </div>
                    );
                })}

                {showButton && (
                    <>
                        <div className="container-saiba-mais">
                            <button
                                onClick={() => setVisible(visible + 10)}
                                className="button-saiba-mais"
                            >
                                Carregar mais
                            </button>
                        </div>
                        <div className="down-arrow-saiba-mais">
                            <button onClick={() => setVisible(visible + 10)}>
                                <IconArrow />
                            </button>
                        </div>
                    </>
                )}
            </div>

            <ThemeContext.Consumer>
                {(cx) => (
                    <ContactBlock
                        description="Agora que mostramos um pouco do nosso trabalho é hora de ouvir seus planos para sua marca"
                        weather={cx?.cookies?.weather}
                        typing={false}
                    />
                )}
            </ThemeContext.Consumer>

            <Footer bg="black" />
        </Layout>
    );
};

export const query = graphql`
    query {
        allAtuoInsights(limit: 5, sort: { fields: [post_id], order: DESC }) {
            nodes {
                post_id
                slug
                title
                description
                post_date
                highlighted
                remoteImage {
                    childImageSharp {
                        gatsbyImageData(
                            placeholder: DOMINANT_COLOR
                            layout: CONSTRAINED
                        )
                    }
                }
            }
        }
    }
`;

export default InsightsPage;
